<template>
  <div class="AddNewSupplierOne">
    <a-spin :spinning="loading">
      <NeoTitle v-if="!edit" :text="$t('customerConfig.base_info')" class="_mb-20" />

      <a-form-model
        ref="form"
        :model="form"
        :label-col="formSet.labelCol"
        :wrapper-col="formSet.wrapperCol"
        :rules="rules"
      >
        <a-row>
          <!-- 供应商税号 -->
          <a-col :span="formSet.span">
            <a-form-model-item
              :label="$t('customerConfig.supplier_tax')"
              :labelAlign="formSet.labelAlign"
              prop="supplierTax"
              v-show="edit"
            >
              <a-input :disabled="canNotEdit" v-model="form.supplierTax" placeholder="" />
            </a-form-model-item>
            <a-form-model-item
              v-show="!edit"
              :label="$t('customerConfig.supplier_tax')"
              :labelAlign="formSet.labelAlign"
            >
              <span>{{ form.supplierTax }}</span>
            </a-form-model-item>
          </a-col>
          <!-- 不编辑  供应商地址 -->
          <!-- 编辑   供应商实体名 -->
          <a-col :span="formSet.span">
            <a-form-model-item
              v-show="edit"
              :label="$t('customerConfig.supplier_name')"
              :labelAlign="formSet.labelAlign"
              prop="supplierName"
            >
              <a-input :disabled="canNotEdit" v-model="form.supplierName" placeholder="" />
            </a-form-model-item>
            <a-form-model-item
              v-show="!edit"
              :label="$t('customerConfig.supplier_address')"
              :labelAlign="formSet.labelAlign"
            >
              <span>{{ form.supplierAddress }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="formSet.span">
            <div class="supplier-admin" v-show="edit">
              <a-form-model-item
                :label="$t('customerConfig.supplier_admin')"
                :labelAlign="formSet.labelAlign"
                prop="supplierAdminName"
                class="supplier-admin-name"
              >
                <a-input v-model="form.supplierAdminName" :placeholder="$t('managerInfo.name')" />
                <!-- _w-pct-43 -->
              </a-form-model-item>
              <a-form-model-item
                :labelAlign="formSet.labelAlign"
                prop="supplierAdminEmail"
                class="supplier-admin-email"
              >
                <a-input v-model="form.supplierAdminEmail" :placeholder="$t('email')" />
              </a-form-model-item>
            </div>
            <a-form-model-item
              v-show="!edit"
              :label="$t('customerConfig.supplier_admin')"
              :labelAlign="formSet.labelAlign"
            >
              <span>
                {{ form.supplierAdminName }}
                <template v-if="$g.noEmpty(form.supplierAdminEmail)">（{{ form.supplierAdminEmail }}）</template>
              </span>
            </a-form-model-item>
          </a-col>
          <!-- 编辑时 供应商地址 -->
          <!-- 不编辑时 空 -->
          <a-col :span="formSet.span" v-show="edit">
            <a-form-model-item
              :label="$t('customerConfig.supplier_address')"
              :labelAlign="formSet.labelAlign"
              prop="supplierAddress"
            >
              <a-input v-model="form.supplierAddress" placeholder="" />
              <!-- <span v-else>{{ form.supplierAddress }}</span> -->
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <!-- 关联产品线 -->
          <a-col :span="formSet.span">
            <a-form-model-item :label="$t('customerConfig.contact_productLine')" :labelAlign="formSet.labelAlign">
              <a-select v-show="edit" v-model="form.contactProductLine" mode="multiple" placeholder="">
                <a-select-option v-for="(item, index) in productLines" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-tooltip placement="right" v-show="edit">
                <template slot="title">
                  <span>{{ $t('customerConfig.tip_select_recommend') }}</span>
                </template>
                <img class="tip-icon" src="@/assets/image/tip-icon.png" alt="" />
              </a-tooltip>
              <div v-show="!edit">
                <NeoTag class="_br0-force _mr-10" v-for="(item, index) of form.contactProductLine" :key="index">{{
                  getLineName(item)
                }}</NeoTag>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <!-- 关联语言对 -->
          <a-col :span="24">
            <a-form-model-item :label="$t('customerConfig.contact_lang_pair')" :labelAlign="formSet.labelAlign">
              <template v-for="($item, index) of form.contactLangPair">
                <div v-show="edit" :key="index" class="_rel" :class="{ '_mt-15': index !== 0 }">
                  <div class="lang_pair">
                    <a-form-item
                      style="margin-bottom: 0px"
                      :validate-status="getValidateStatus($item, $item.sourceCode)"
                      :help="getValidateTip($item, $item.sourceCode)"
                    >
                      <a-select
                        v-model="$item.sourceCode"
                        placeholder="Source"
                        showSearch
                        allowClear
                        :filter-option="langFilterOption"
                        class="select-w"
                      >
                        <a-select-option
                          v-for="(item, index) in langPair"
                          :key="index"
                          :value="item.code"
                          :disabled="sourceDisable($item, item)"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <div class="rignt-arrow">
                      <img src="@/assets/image/translationRecord-rignt-arrow.png" alt="" />
                    </div>
                    <a-form-item
                      style="margin-bottom: 0px"
                      :validate-status="getValidateStatus($item, $item.targetCode)"
                      :help="getValidateTip($item, $item.targetCode)"
                    >
                      <a-select
                        v-model="$item.targetCode"
                        placeholder="Target"
                        showSearch
                        allowClear
                        :filter-option="langFilterOption"
                        class="select-w"
                      >
                        <a-select-option
                          v-for="(item, index) in langPair"
                          :key="index"
                          :value="item.code"
                          :disabled="targetDisable($item, item)"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <!-- 第一行固定显示 -->
                  <template v-if="index === 0">
                    <a-tooltip placement="right">
                      <template slot="title">
                        <span>{{ $t('customerConfig.tip_select_recommend2') }}</span>
                      </template>
                      <img class="tip-icon1" src="@/assets/image/tip-icon.png" alt="" />
                    </a-tooltip>
                    <!-- 添加 -->
                    <img
                      class="_abs _point _top-10"
                      style="right: -62px"
                      @click="addLangPairSel"
                      src="@/assets/image/plus-icon.png"
                      alt=""
                    />
                  </template>

                  <!-- 删除 -->
                  <img
                    class="_abs _point _top-10 _w-20"
                    style="right: -31px"
                    v-if="index > 0"
                    @click="delLangPairSel($item.id)"
                    src="@/assets/image/cut-icon.png"
                    alt=""
                  />
                </div>
                <div v-show="!edit" :key="'info2-' + index" class="_h-20" :class="{ '_mt-15': index !== 0 }">
                  <section class="_flex c-2 fs-2 _h-20" v-if="$item.sourceCode && $item.targetCode">
                    <div>{{ getLanguage($item.sourceCode) }}</div>
                    <div class="rignt-arrow _mlr-5">
                      <span>></span>
                    </div>
                    <div>{{ getLanguage($item.targetCode) }}</div>
                  </section>
                </div>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <!-- 生产对接 -->
      <footer class="produce-dock">
        <NeoTitle :text="$t('companyInfo.Pline_dock')" class="_mb-20" />
        <a-radio-group v-model="radioVal" class="_ml-22" @change="radioValChange">
          <a-radio v-if="radioVal === 2 || edit" :value="2" class="c-2">{{
            $t('customerConfig.dock_jingyi_internal')
          }}</a-radio>
          <a-radio v-if="radioVal === 0 || edit" :value="0" class="c-2">{{
            $t('customerConfig.no_dock_produce_sys')
          }}</a-radio>
        </a-radio-group>
        <div class="_mt-30">
          <a-button v-show="!edit" class="_w-100" @click="handleModify" type="primary">{{ $t('change') }}</a-button>
          <a-button v-show="edit" class="_w-100" @click="handleSave" :loading="btnloading" type="primary">{{
            $t('save')
          }}</a-button>
        </div>
      </footer>
    </a-spin>
  </div>
</template>

<script>
import LangPair from './LangPair'
import debounce from 'lodash/debounce'
import NeoTag from '@/components/common/NeoTag'
import { Icon } from 'ant-design-vue'

const { validate } = window.$g

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LangPair,
    NeoTag,
  },
  data() {
    return {
      formSet: {
        span: 12,
        labelAlign: 'right',
        labelCol: { span: 4 },
        wrapperCol: { span: 11 },
      },
      form: {
        supplierTax: '',
        supplierName: '',
        supplierAddress: '',
        supplierAdminName: '',
        supplierAdminEmail: '',
        contactProductLine: undefined,
        contactLangPair: [{ id: 0, sourceCode: undefined, targetCode: undefined }],
      },
      productLines: [],
      rules: {
        supplierTax: [validate.required, validate.only_num_letter, validate.size_20],
        supplierName: [validate.required, validate.regular_str_less_size_100],
        supplierAddress: [validate.required, validate.regular_str_less_size_100],
        supplierAdminName: [validate.required],
        supplierAdminEmail: [validate.required, validate.email],
      },
      radioVal: 2,
      langPair: [],
      addedLangPairAmount: 2,
      langCodeMap: new Map(),
      langPairList: [],
      isClearLangPair: false,
      edit: false,
      langPairs: [],
      baseInfo: [],
      btnloading: false,
      loading: false,
      langPairData: [],
      userId: '',
      noDocking: false,
    }
  },
  mounted() {
    this.fetchAll()
  },
  watch: {
    // addedLangPairAmount(l) {
    //   if (l >= 3) {
    //     this.$nextTick(() => {
    //       const el = document.getElementById('selScroll')
    //       el.scrollTop = el.scrollHeight
    //       // console.log(`el`, el)
    //       // console.log('当前滚动条位置:' + el.scrollTop)
    //       // console.log('当前可滚动区域容器的高度:' + el.scrollHeight)
    //     })
    //   }
    // },
    // show(v) {
    //   v && this.getBaseInfo()
    // },
    // $route() {
    //   this.userId = this.$route.query.id
    // },
  },
  computed: {
    contactLangPair() {
      if (this.form.contactLangPair.length > 0) {
        return this.form.contactLangPair
      } else {
        return this.langPairData
      }
    },
    canEdit() {
      return this.edit && this.baseInfo.status === 0
    },
    canNotEdit() {
      return !this.edit || this.baseInfo.status === 1
    },
    _langPair() {
      return this.$store.state.app.langList
    },
    id() {
      return this.$route.query.id
    },
    sourceCodeList() {
      return Array.from(
        new Set([...this.form.contactLangPair.filter((item) => item.sourceCode).map((item) => item.sourceCode)])
      )
    },
    targetCodeList() {
      return Array.from(
        new Set([...this.form.contactLangPair.filter((item) => item.targetCode).map((item) => item.targetCode)])
      )
    },
  },
  methods: {
    radioValChange() {
      if (this.radioVal === 0) {
        this.noDocking = true
      } else {
        this.noDocking = false
      }
    },
    getLineName(id) {
      return this.productLines.find((item) => item.id === id)?.name
    },
    fetchAll() {
      this.langPair = this._langPair
      this.getBaseInfo()
      this.getCusProductLineList()
    },
    getValidateStatus({ sourceCode, targetCode }, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    getValidateTip({ sourceCode, targetCode }, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return this.$t('validate.required')
      } else {
        return ''
      }
    },
    sourceDisable($item, item) {
      const { langPairData, targetCodeList } = this
      const { targetCode } = $item

      const targetItem = langPairData.find((obj) => obj.targetCode === targetCode && this.$g.noEmpty(obj.sourceCode))
      const factor1 = item.code === targetCode
      const factor2_1 = targetCodeList.includes(targetCode)
      const factor2_2 = item.code === targetItem?.sourceCode
      const factor2 = factor2_1 && factor2_2
      return factor1 || factor2
    },

    targetDisable($item, item) {
      const { sourceCodeList, langPairData } = this
      const { sourceCode } = $item
      const targetItem = langPairData.find((obj) => obj.sourceCode === sourceCode && this.$g.noEmpty(obj.targetCode))
      const factor1 = item.code === sourceCode
      const factor2_1 = sourceCodeList.includes(sourceCode)
      const factor2_2 = item.code === targetItem?.targetCode
      const factor2 = factor2_1 && factor2_2
      return factor1 || factor2
    },
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    async checkSupByTaxIdNumber() {
      // eslint-disable-next-line no-unused-vars
      let res = null
      try {
        this.btnloading = true
        const params = {
          cusEntityId: this.$store.state.app.userInfo.agencyId,
          taxIdNumber: this.form.supplierTax,
        }
        const data = await this.$http('checkSupByTaxIdNumber', params)
        this.btnloading = false
        res = data
        if (data) {
          this.$message.error(this.$t('customerConfig.tax_has'))
        }
      } catch (err) {
        res = 1
        this.btnloading = false
        this.$httpNotify(err)
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return res
      }
    },
    async checkBySupName() {
      let res = null
      try {
        this.btnloading = true
        const params = {
          cusEntityId: this.$store.state.app.userInfo.agencyId,
          name: this.form.supplierName,
        }
        const data = await this.$http('checkBySupName', params)
        this.btnloading = false
        res = data
        if (data) {
          return this.$message.error(this.$t('customerConfig.entity_has'))
        }
      } catch (err) {
        res = 1
        this.btnloading = false
        this.$httpNotify(err)
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return res
      }
    },

    async saveBaseInfo() {
      const go = async () => {
        try {
          const noChange1 = this.baseInfo.taxIdNumber === this.form.supplierTax
          const noChange2 = this.baseInfo.name === this.form.supplierName
          const noChange = noChange1 && noChange2

          let repeat1 = null
          let repeat2 = null

          if (!noChange1) {
            repeat1 = !!(await this.checkSupByTaxIdNumber())
          }
          if (!noChange2) {
            repeat2 = !!(await this.checkBySupName())
          }

          if (noChange || (!repeat1 && !repeat2)) {
            this.btnloading = true

            const f = this.form
            const b = this.baseInfo
            const params = {
              id: this.id,
              address: f.supplierAddress,
              cusEntityId: b.cusEntityId,
              name: f.supplierName,
              taxIdNumber: f.supplierTax,
              topManagerEmail: f.supplierAdminEmail,
              topManagerName: f.supplierAdminName,
              produceSystem: this.radioVal,
              languagePairList: f.contactLangPair.filter((item) => item.sourceCode && item.targetCode),
              productLineIdList: f.contactProductLine || [],
            }
            await this.$http('updateSupEntity', params)
            this.getBaseInfo()
            this.btnloading = false
            this.edit = false
            this.$emit('editing', this.edit)
          }
        } catch (error) {
          this.btnloading = false
          this.$httpNotify(error)
        }
      }

      if (this.baseInfo.produceSystem && !this.radioVal) {
        this.$confirm({
          title: '警告',
          centered: false,
          class: 'coConfig_AddManager_confirm_class',
          icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
          content: '选择不对接生产系统后，原先供应商端的生产配置将会被清除，是否继续？',
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onOk: () => {
            go()
          },
        })
      } else {
        go()
      }
    },
    async getBaseInfo() {
      this.loading = true
      try {
        const data = await this.$http({ key: 'supEntityInfo', params: { id: this.id } })
        this.$bus.$emit('SupplierBaseInfoDetail', data)
        this.radioVal = data.produceSystem
        this.form.supplierTax = data.taxIdNumber
        this.form.supplierName = data.name
        this.form.supplierAddress = data.address
        this.form.supplierAdminName = data.topManagerName
        this.form.supplierAdminEmail = data.topManagerEmail
        this.form.contactProductLine = data.productLineIdList
        data.languagePairList.length && (this.form.contactLangPair = data.languagePairList)
        this.baseInfo = data
        this.$emit('getName', this.form.supplierName)
        this.loading = false
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    async getCusProductLineList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusProductLineList', { entityId: agencyId })
        this.productLines = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    getLanguage(val) {
      const data = this.$store.getters['app/getLangNameByCode'](val)
      return val === '' ? '---' : data
    },
    langPairNoSingle() {
      return this.form.contactLangPair.every((item) => !!item.sourceCode === !!item.targetCode)
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid && this.langPairNoSingle()) {
          this.saveBaseInfo()
        } else {
          return false
        }
      })
    },
    handleModify() {
      this.edit = true
      this.$emit('editing', this.edit)
    },
    clearForm() {
      for (const key in this.form) {
        this.form[key] = undefined
      }
      this.isClearLangPair = true
      this.sourceCodeList = []
      this.targetCodeList = []
      this.langPairList = []
    },
    // eslint-disable-next-line no-unused-vars
    addLangPairSel: debounce(function () {
      // this.addedLangPairAmount++
      this.form.contactLangPair.push({
        id: this.form.contactLangPair[this.form.contactLangPair.length - 1].id + 1,
        sourceCode: undefined,
        targetCode: undefined,
      })
    }, 100),
    delLangPairSel: debounce(function (id) {
      this.$g.del(this.form.contactLangPair, id)
    }, 100),
    onRadioChange() {},
  },
}
</script>

<style lang="less" scoped>
.AddNewSupplierOne {
  .tip-icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: -29px;
    margin: auto;
  }
  .tip-icon1 {
    position: absolute;
    right: -29px;
    top: 10px;
  }
  .tip-icon2 {
    position: absolute;
    right: -29px;
    top: 58px;
  }
  .lang_pair {
    display: flex;
    line-height: 32px;
    .ant-form-item {
      width: calc(calc(100% - 29px) / 2);
    }
    .rignt-arrow {
      width: 29px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 18px;
      color: #46546c;
      line-height: 25px;
      padding: 0 10px;
      margin-top: 4px;
    }
    .select-w {
      width: 100%;
    }
  }
  .add_length_one {
    position: absolute;
    top: 10px;
    right: -61px;
    cursor: pointer;
  }
  .add_length_more {
    position: absolute;
    top: 10px;
    right: -30px;
    cursor: pointer;
  }
  ::v-deep {
    .ant-form-item-label {
      width: 102px;
    }
    .supplier-admin {
      width: calc(102px + 45.83333333%);
      height: 40px;
      // border: 1px solid#000;
      &-name,
      &-email {
        display: inline-block;
      }
      &-name {
        float: left;
        margin-right: 1%;
        width: 51.5%;
        .ant-form-item-control-wrapper {
          width: calc(100% - 102px);
        }
      }
      &-email {
        float: right;
        width: 46%;
        .ant-form-item-control-wrapper {
          width: 100%;
        }
      }
    }
  }
}
</style>
